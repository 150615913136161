import type { MarkerProps } from 'react-map-gl'

export const GLOBAL_MIN_SEARCH_LENGTH = 3
export const GLOBAL_SEARCH_DEBOUNCE_TIME = 400
export const FILTER_MIN_SEARCH_LENGTH = 4

interface Allowlist {
    [mimetype: string]: string[] // Mimetype maps to an array of extensions
}
export const IS_DEV_ENV = import.meta.env.VITE_API_LOCAL_DEV === 'true'
const backendUrl = import.meta.env.VITE_API_BASE_URL

export const ENV_WITH_ROOT_ALL_AGENCIES =
    IS_DEV_ENV ||
    import.meta.env.MODE === 'stoneo' ||
    import.meta.env.MODE === 'staging' ||
    import.meta.env.MODE === 'development'

export const ENVIRONMENT = backendUrl.includes('stoneo-api.avest.fr')
    ? 'stoneo'
    : backendUrl.includes('dev-api.avest.fr')
    ? 'dev'
    : backendUrl.includes('api.avest.fr')
    ? 'prod'
    : 'localhost'

export const UPLOADED_FILES_CRITERIAS: { MAX: number; ALLOWED_LIST: Allowlist } = {
    MAX: 5,
    ALLOWED_LIST: {
        'image/*': ['.png', '.jpeg', '.jpg', '.png', '.gif', '.svg', '.webp', '.heic', '.heif'],
        'video/*': ['.mp4'],
        'audio/*': ['.mpeg'],
        'application/pdf': ['.pdf'],
        'text/plain': ['.txt'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        'application/vnd.ms-excel': ['.xls'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
        'application/vnd.ms-powerpoint': ['.ppt'],
    },
}

export const LOGIN_INPUT_CODE_LENGTH = 6

export const DEFAULT_AGENCY_COORDS: Pick<MarkerProps, 'latitude' | 'longitude'> = {
    latitude: 48.834116,
    longitude: 2.248313,
}
